<template>
	<div
		class="data-list-container main-list-view">
	<vs-navbar class="main-toolbar" type="flat" color="#4690DE">
		<div slot="title">
			<vs-navbar-title>
				<div class="logo-top-left">
					<img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pt-3 pl-3"/>
				</div>
			</vs-navbar-title>
		</div>
		<vs-navbar-item class="pl-10">
			<div class="text-register">
				<router-link to="/register">{{ $t('Register') }}</router-link>
			</div>
		</vs-navbar-item>
		<vs-navbar-item class="pl-4">
			<vs-button @click="login" color="#4690DE" class="mr-4 btn-login-upcoming">{{$t('Login')}}</vs-button>
		</vs-navbar-item>
    </vs-navbar>

	<vs-row vs-justify="center" class="mt-12">
		<vs-row vs-justify="center">
			<vs-col type="flex" vs-justify="center" vs-align="center" class="ml-2 mr-2 xs:w-1/1 sm:w-3/4 lg:w-1/2">
				<div class="flex flex-wrap justify-between mt-5 mb-5 event-user">
					<div @click="upcomingEvent()" class="title-upcoming-events poniter">{{$t('Upcoming Events')}}</div>
					<div @click="pastEvent()" class="pt-12 pointer">{{$t('Past Events')}}</div>
				</div>
			</vs-col>
		</vs-row>
		<vs-col type="flex" vs-justify="center" vs-align="center" class="ml-2 mr-2 xs:w-1/1 sm:w-3/4 lg:w-1/2" v-for="(item, indexitem) in events" :key="indexitem">
			<vs-card actionable class="cardx relative event-user">
				<div v-if="item.feature_image == null" slot="media" class="thumbnail-event">
					<router-link :to="`/event/${item.slug}/details`">
						<div class="initial-event">{{ generateInitial(item) }}</div>
						<!-- <img src="@/assets/images/logo/default-galleries.png" alt="Galleries" class="responsive card-img-top-upcoming" /> -->
					</router-link>
					<div class="event-desc-cover">
						<div class="event-desc pt-4 pb-3">
							<router-link :to="`/event/${item.slug}/details`">
								<div class="item-name truncate text-white">{{ item.name }}</div>
							</router-link>
							<p class="item-date truncate text-white">{{ dateFormat(item.start_time) }}</p>
						</div>
						<div class="pl-4
						pb-4">
							<vs-button color="#4690DE" class="mr-4 btn-login-upcoming">{{$t('Free')}}</vs-button>
						</div>
					</div>
				</div>
				<div v-if="item.feature_image != null" slot="media">
					<router-link :to="`/event/${item.slug}/details`">
						<div><img :src="item.feature_image" alt="img" class="responsive card-img-top-upcoming" /></div>
					</router-link>
					<div class="event-desc-cover">
						<div class="event-desc pt-4 pb-3">
							<router-link :to="`/event/${item.slug}/details`">
								<div class="item-name truncate text-white">{{ item.name }}</div>
							</router-link>
							<p class="item-date truncate text-white">{{ dateFormat(item.start_time) }}</p>
						</div>
						<div class="pl-4
						pb-4">
							<vs-button color="#4690DE" class="mr-4 btn-login-upcoming">{{$t('Free')}}</vs-button>
						</div>
					</div>
				</div>
			</vs-card>
		</vs-col>
	</vs-row>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import eventsApi from '@/api/event';

import {
  dateFormat,
} from '@/lib/helper';

export default {
  name: 'Events',
  components: {
  },
  data() {
    return {
      keyword: '',
      events: [],
      isFetching: false,
      isAll: false,
      page: 1,
      limit: 10,
      orderBy: 'created_at',
      sortBy: 'asc',
      totalPages: 0,
      totalEvents: 0,
      selected: [],
      selectedItem: null,
      isFormVisible: false,
      isDeleteVisible: false,
      isDeleting: false,
      isMounted: false,
      addNewDataSidebar: false,
      sidebarData: {},
    };
  },
  methods: {
    login() {
      this.$router.push('/login').catch(() => {});
    },
    dateFormat(date) {
      return dateFormat(date, 'DD MMMM YYYY HH:mm A');
    },
    upcomingEvent() {
      this.sortBy = 'desc';
      this.fetch();
    },
    pastEvent() {
      this.sortBy = 'asc';
      this.fetch();
    },
    fetch(reset) {
      this.$vs.loading();
      this.isFetching = true;
      if (reset) {
        this.page = 1;
        this.events = [];
      }
      const keyword = this.keyword;
      const params = {
        page: this.page,
        limit: this.limit,
        order_by: this.orderBy,
        sort_by: this.sortBy,
      };
      if (keyword !== '') {
        params.keyword = keyword;
      }
      const callback = (response) => {
        const events = response.data;
        const lastPage = response.last_page;
        this.events = events;
        this.isAll = events.length === 0 || events.length < this.limit;
        // this.page++;
        if (lastPage) {
          this.totalPages = lastPage;
        }
        this.totalEvents = response.total;
        this.isFetching = false;
        this.$vs.loading.close();
      };
      const errorCallback = () => {
        this.isFetching = false;
        this.$vs.loading.close();
      };
      eventsApi.getList(params, callback, errorCallback);
    },
    generateInitial(event) {
      const words = event.name ? event.name.split(' ') : 'AN';
      const initial = words.length > 1 ? `${words[0][0]}${words[1][0]}` : words[0][0];
      return initial;
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
    }),
  },
  created() {
    this.fetch();
    document.addEventListener('click', this.onSelectAll);
  },
  destroyed() {
    document.removeEventListener('click', this.onSelectAll);
  },
  mounted() {
    this.isMounted = true;
  },
  watch: {
    page() {
      this.fetch();
    },
  },
};
</script>

<style lang="scss" scoped>
.event-user{
	margin-left: auto;
	margin-right: auto;
		max-width: 681px;
}
.card-img-top-upcoming {
	border-top-left-radius: .5rem;
	border-top-right-radius: .5rem;
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
	min-height: 473px;
	max-height: 473px;
	object-fit: cover;
}
.item-name {
	font-size: 23px;
	color: #000;
}
.item-date {
	font-size: 13px;
	color: #b1b1b1;
}
.event-menu{
	position: absolute;
	top: 0;
	margin-top: 1em;
	right: 0;
}
.event-desc{
	z-index: 3;
	padding-left: 1em;
}
.event-desc-cover{
	background-color: rgba(0,161,237,0.5);
	border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
	width: 100%;
	position: absolute;
	bottom: 0;
	margin-top: 1em;
	left: 0;
}
.thumbnail-event{
	position: relative;
}
.wrapper-event{
	overflow-x: hidden!important;
}
.page {
    padding-left: 15rem;
    margin-right: 16.5rem;
}
.main-toolbar{
	background-color: #F8F8F8!important;
}
.logo-top-left{
	width: 174.18px;
	height: 65.75px;
}
.text-register{
	font-size: 18px!important;
	color: #4690DE;
}
.btn-login-upcoming{
	width: 113.8px;
	height: 36px;
}
.title-upcoming-events{
	font-size: 46px;
	font-weight: 100;
	color:	#363636;
}
.event-upcoming-desc{
	position: relative;
	top:0;
}
.thumbnail-event{
	min-height: 473px;
	max-height: 473px;
	padding: 2em;
	padding-top: 4.5em;
	.initial-event {
	text-align: center;
	font-size: 7.5em;
	padding: 24px;
	font-weight: bold;
	max-width: 210px;
	margin: 0 auto;
	border-radius: 50%;
	background-color: #00a1ed;
	color: #fff;
	}
}
</style>
